import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
// import "./dashboard.css";
import we from "../Wedding.png";
import load from "../load.gif";
import axios from "axios";
import { Pagination, Tooltip } from "antd";

function Dashboard() {
  let url;
  const [productsShow, setProductsShow] = useState(false);
  const [paginationShow, setpaginationShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registerId, setRegisterId] = useState("");
  const [id, setId] = useState("");
  const [registerUrl, setRegisterUrl] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPageCount, settotalPageCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [store, setStore] = useState("Amazon");
  const [products, setProducts] = useState([]);

  const getProducts = () => {
    console.log(store);
    if (store === "Amazon") {
      url = `https://sfpditr66i.execute-api.ap-south-1.amazonaws.com/Demo/Amazon?registryid=${registerUrl}&source=amazon&page=${currentPage}`;
    } else if (store === "Target") {
      url = `https://sfpditr66i.execute-api.ap-south-1.amazonaws.com/Demo/Amazon?registryid=${registerUrl}&source=target&page=${currentPage}`;
    } else if (store === "Walmart") {
      url = `https://sfpditr66i.execute-api.ap-south-1.amazonaws.com/Demo/Amazon?registryid=${registerUrl}&source=walmart&page=${currentPage}`;
    } else if (store === "Etsy") {
      url = `https://sfpditr66i.execute-api.ap-south-1.amazonaws.com/Demo/Amazon?registryid=${registerUrl}&source=etsy&page=${currentPage}`;
    }

    setLoading(true);
    setProductsShow(false);
    if (registerUrl) {
      setErrorMsg("");
      axios
        .get(url)
        .then((res) => {
          setLoading(false);
          console.log(res);
          if (res.data.Success === true) {
            if (store === "Amazon" || store === "Walmart") {
              setpaginationShow(true);
            }

            console.log(res.data);
            setProducts(res.data.RegistryItems);
            settotalPageCount(res.data.TotalCount);
            setRegisterId(res.data.RegistryId);
            setProductsShow(true);
          } else {
            setProductsShow(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setProductsShow(false);
          setpaginationShow(false);
          console.log(error.response?.data?.ErrorMessage);

          setErrorMsg("Invalid Registry URL!");
        });
    } else {
      setProductsShow(false);
      setLoading(false);
      setpaginationShow(false);

      setErrorMsg("Registry URL cannot be empty. Please enter a Registry URL");
    }
  };

  const handleChange = (e) => {
    setRegisterUrl(e.target.value);

    console.log(e.target.value);
  };

  const imgClick = (productUrl) => {
    console.log(productUrl);
    if (store === "Amazon") {
      const url = `https://www.amazon.com/wedding/registry/${registerId}`;
      window.open(url, "_blank");
    } else if (store === "Target") {
      const url = `https://www.target.com/gift-registry/gift-giver?registryId=${registerId}`;
      window.open(url, "_blank");
    } else if (store === "Walmart") {
      const url = `https://www.walmart.com/registry/WR/${registerId}`;
      window.open(url, "_blank");
    } else if (store === "Etsy") {
      const url = `https://www.etsy.com/registry/${registerId}/?ref=registry_selectname&section=all`;
      window.open(url, "_blank");
    }
  };

  const handlePageChange = (page) => {
    if (store === "Amazon") {
      url = `https://sfpditr66i.execute-api.ap-south-1.amazonaws.com/Demo/Amazon?registryid=${registerUrl}&source=amazon&page=${page}`;
    } else if (store === "Target") {
      url = `https://sfpditr66i.execute-api.ap-south-1.amazonaws.com/Demo/Amazon?registryid=${registerUrl}&source=target&page=${page}`;
    } else if (store === "Walmart") {
      url = `https://sfpditr66i.execute-api.ap-south-1.amazonaws.com/Demo/Amazon?registryid=${registerUrl}&source=walmart&page=${page}`;
    }

    setcurrentPage(page);
    setLoading(true);
    setProductsShow(false);
    if (registerUrl) {
      setErrorMsg("");

      axios
        .get(url)
        .then((res) => {
          setLoading(false);
          console.log(res);
          if (res.data.Success === true) {
            setpaginationShow(true);
            console.log(res.data);
            setProducts(res.data.RegistryItems);
            settotalPageCount(res.data.TotalCount);
            setProductsShow(true);
          } else {
            setProductsShow(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setProductsShow(false);
          setpaginationShow(false);
          console.log(error.response?.data?.ErrorMessage);

          setErrorMsg("Invalid Registry URL!");
        });
    } else {
      setProductsShow(false);
      setLoading(false);
      setpaginationShow(false);
      if (store === "Walmart") {
        setErrorMsg("Registry Id cannot be empty. Please enter a Registry Id");
      } else {
        setErrorMsg(
          "Registry URL cannot be empty. Please enter a Registry URL"
        );
      }
    }
  };

  const handleradiochange = (e) => {
    setProductsShow(false);
    setRegisterUrl("");
    setId("");
    setpaginationShow(false);
    setcurrentPage(1);
    setErrorMsg("");
    console.log(e.target.value);
    setStore(e.target.value);
  };

  return (
    <div className="container-fluid ">
      <Row
        className="head pb-3 pt-3"
        style={{
          borderBottom: "1px solid rgb(219, 224, 235)",
          boxShadow: "0px 2px 5px rgb(219, 224, 235 ",
        }}
      >
        <div className="img-box d-flex justify-content-center ">
          <img src={we} alt="" height={60} width={400}></img>
        </div>
      </Row>
      <Container className=" cont mt-5 ">
        <Row>
          <h5>Choose Store</h5>
        </Row>
        <Row className="mt-3">
          <div className=" d-flex  flex-wrap">
            <div>
              <Form.Check
                label="Amazon"
                type="radio"
                className="user-select-none me-3"
                id="radio-button-id"
                name="radioGroup"
                defaultChecked={true}
                value={"Amazon"}
                onChange={handleradiochange}
              />
            </div>
            <div>
              <Form.Check
                label="Target"
                type="radio"
                name="radioGroup"
                className="user-select-none me-3"
                id="radio-button-id2"
                value={"Target"}
                onChange={handleradiochange}
              />
            </div>
            <div>
              <Form.Check
                label="Walmart"
                type="radio"
                name="radioGroup"
                className=" me-3"
                id="radio-button-id3"
                value={"Walmart"}
                onChange={handleradiochange}
              />
            </div>
            <div>
              <Form.Check
                label="Etsy"
                type="radio"
                name="radioGroup"
                value={"Etsy"}
                onChange={handleradiochange}
                className=" me-3 "
                id="radio-button-id3"
              />
            </div>
          </div>
        </Row>

        <Row className="mt-4 d-flex justify-content-center flex-wrap">
          <div className="search d-flex flex-wrap justify-content-center  ">
            <div className="me-2 mt-3">
              <Form.Label> Registry URL :</Form.Label>
            </div>
            <div className=" w-50 me-3 mt-3">
              <Form.Control
                type="text"
                placeholder="Enter Your Registry URL"
                className={`form-control ${
                  errorMsg !== "" ? "border-danger" : ""
                }`}
                name="registerId"
                value={registerUrl}
                onChange={handleChange}
              />
            </div>

            <div className="but mt-3">
              <Button onClick={getProducts}>Get Products</Button>
            </div>
          </div>
        </Row>

        {productsShow && (
          <Row className="products-row mt-3 mb-3 ">
            {products.map((pro, index) => (
              <Col className="mt-3 " key={index} md={6} sm={12} xs={12}>
                <div className="products">
                  <div className="d-flex justify-content-between ps-3 pe-3 mb-2">
                    <div
                      style={{
                        color:
                          pro.QtyRequested === pro.QtyPurchased
                            ? "red"
                            : "green",
                      }}
                    >
                      {pro.QtyRequested === pro.QtyPurchased ? "Purchased" : ""}
                    </div>
                    <div style={{ color: pro.InStock ? "green" : "red" }}>
                      {pro.InStock ? "Instock" : "Out of stock"}
                    </div>
                  </div>
                  <div
                    className="content d-flex align-items-center justify-content-center"
                    style={{ cursor: "pointer" }}
                  >
                    <div className=" hover-color pb-3 me-2 ms-1">
                      <img
                        src={pro.ImageUrl}
                        alt=""
                        height={100}
                        width={100}
                        onClick={() => imgClick(pro.ProductUrl)}
                      />
                    </div>
                    <div className="w-100 me-2">
                      <div
                        className="hover-color"
                        style={{overflow:"hidden"}}
                        onClick={() => imgClick(pro.ProductUrl)}
                      >
                        {pro.ProductTitle}
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-1 mb-1 flex-wrap">
                      <div style={{ fontWeight: "600" }} className="">
                        {pro?.ItemPrice?.DisplayString !== null && pro?.ItemPrice?.DisplayString !== ""
                          ? pro?.ItemPrice?.DisplayString
                          : pro?.ItemPrice?.Amount === 0
                          ? ""
                          : "$" + pro?.ItemPrice?.Amount}
                      </div>
                      <div className="requested">
                      <div >
                      Requested Qty : {pro.QtyRequested}
                      </div>
                      <div>
                      Purchased Qty : {pro.QtyPurchased}
                      </div>
                      </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
        {errorMsg && (
          <div className="text-center text-danger mt-2">{errorMsg}</div>
        )}
        {loading && (
          <div className="h-100 d-flex justify-content-center align-items-center mt-5 pt-5">
            <img src={load} alt="" height={50} width={50} />
          </div>
        )}
        {paginationShow && (
          <Row>
            <div className=" d-flex justify-content-center mt-3 mb-4 pb-3">
              <Pagination
                variant="outlined"
                shape="rounded"
                pageSize={store === "Walmart" ? 28 : 40}
                current={currentPage}
                total={totalPageCount}
                showSizeChanger={false}
                onChange={handlePageChange}
              />
            </div>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default Dashboard;
