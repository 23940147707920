import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./UserLogin/Login";
import ResetPassword from "./UserLogin/ResetPassword";
import Dashboard from "./Component/Dashboard";
import SignUp from "./UserLogin/SignUp";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<Dashboard />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
